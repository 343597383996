import "./App.css";
import React from "react";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PrivateRoute } from "./components";
import { Home, Logout } from "./pages";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <Router>
        <Switch>
          <PrivateRoute path="/" exact component={Home} />
          <PrivateRoute path="/logout" exact component={Logout} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
