import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const render = (props) => <Component {...props} />;

  return <Route path={path} render={render} {...rest} />;
};

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
